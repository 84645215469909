import {BooleanNumField} from '@/components/field'
import {Product} from '@/types'
import {Typography} from '@mui/material'
import * as React from 'react'
import {ReactNode, useCallback} from 'react'
import {
	Datagrid,
	DatagridProps,
	DateField,
	EditButton,
	FunctionField,
	Link,
	ReferenceField,
	TextField,
	useCreatePath
} from 'react-admin'

const DatagridActionsColumn = ({label, children}: {
	label: string | ReactNode,
	children: ReactNode
}) => <>{children}</>

export default function ProductsDatagrid(props: DatagridProps) {

	const createPath = useCreatePath()
	const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

	return (
		<Datagrid
			optimized
			rowClick="edit"
			{...props}
		>
			<TextField source="id"/>
			{/*<TextField source="article"/>*/}
			<FunctionField
				source="name"
				label="Libellé"
				render={(record: Product) =>
					<>
						{record.parent &&
                            <Link
                                to={createPath({resource: 'products', type: 'edit', id: record.parent})}
                                onClick={handleClick}
                                style={{textDecoration: 'none'}}
                            >
                                <ReferenceField source="parent" reference="products" label="">
                                    <TextField source="name" color="primary" sx={{
										display: 'block',
										fontSize: '12px',
										'&:hover': {
											color: 'secondary.main'
										}
									}}/>
                                </ReferenceField>

                            </Link>
						}
						<Link
							to={createPath({resource: 'products', type: 'edit', id: record.id})}
							onClick={handleClick}
							style={{textDecoration: 'none'}}
						>
							<TextField source="name" color="primary"/>
							<TextField source="name_alt" color="black" sx={{
								display: 'block',
								fontStyle: 'italic',
								color: 'black !important',
								fontSize: '12px'
							}}/>
						</Link>
					</>
				}
			/>
			{/*<ReferenceField source="id" reference="products" link="edit" label="Libellé">*/}
			{/*    <TextField source="name" />*/}
			{/*    <TextField source="name_alt" color="black" sx={{ display: 'block', fontStyle: 'italic', color: 'black !important', fontSize: '12px'}} />*/}
			{/*</ReferenceField>*/}
			<TextField source="typeName" label="Type"/>
			<TextField source="categoryName" label="Catégorie"/>
			<DateField source="updated_at"/>
			<TextField source="trusted_terroir.trust" label="Confiance max"/>
			<FunctionField
				source="is_published"
				label="Publié"
				render={(record: any, source: any) =>
					<BooleanNumField
						label="Publié"
						source={source}
					/>
				}
			/>
			<FunctionField
				source="is_contribution"
				label="Contribution"
				render={(record: Product) => {
					return <>
						<Typography>{record.is_contribution ? 'Oui' : 'Non'}</Typography>
						<ReferenceField source="contributor" reference="users" label="Utilisateur">
							<TextField source="email"/>
						</ReferenceField>
					</>
				}}
			/>
			{props.children}
			<DatagridActionsColumn
				label=""//{<LinkUserDialog/>}
			>
				<EditButton/>
			</DatagridActionsColumn>
		</Datagrid>
	)
}
