import FullNameField from '@/components/field/FullNameField'
import DefaultEditor from '@/components/form/DefaultEditor'
import PreviewImage from '@/layout/PreviewImage'
import {getProductLink} from '@/lib/link'
import ProductCommunes from '@/products/ProductCommunes'
import ProductContributions from '@/products/ProductContributions'
import ProductTerroirs from '@/products/ProductTerroirs'
import TagCreate from '@/tags/ModalTagCreate'
import {Product, User} from '@/types'
import LaunchIcon from '@mui/icons-material/Launch'
import {Alert, AlertTitle, Box, Chip, Grid, Link, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	CheckboxGroupInput,
	Datagrid,
	DeleteButton,
	Edit,
	FunctionField,
	Link as RALink,
	ReferenceArrayField,
	ReferenceArrayInput,
	ReferenceField,
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	ShowButton,
	SimpleForm,
	TextField,
	TextInput,
	Toolbar,
	useCreatePath,
	useEditController,
	useNotify,
	useTranslate
} from 'react-admin'
import {Link as RouterLink} from 'react-router-dom'

import './style.css'
// import {Link} from 'react-router-dom'

const transform = (data: any) => {
	return {
		...data,
		...(data && data.labels && {
			is_aop: data.labels.includes('is_aop'),
			is_igp: data.labels.includes('is_igp')
		})
	}
}

export default function ProductEdit() {

	const translate = useTranslate()
	const {record, isLoading: loading} = useEditController<Product>()

	const [optionsT, setOptionsT] = useState<any>([])
	const [availableCategories, setAvailableCategories] = useState<any>([])

	useEffect(() => {
		if (record) {
			document.title = record.name
		}
	}, [record])

	useEffect(() => {

		const getOptions = async () => {

			/**
			 * Types
			 */
			fetch(process.env.REACT_APP_DB_LOPT + '/products/types')
				.then(res => res.json())
				.then((res) => {
					// console.log(res)
					setOptionsT(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
				})
			/**
			 * Categories
			 */
			fetch(process.env.REACT_APP_DB_LOPT + '/products/categories?_end=null')
				.then(res => res.json())
				.then((res) => {
					// console.log(res)
					setAvailableCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
				})
		}

		getOptions()

	}, [])

	const createPath = useCreatePath()
	console.log(record)
	return (
		<Edit
			mutationMode="pessimistic"
			title={<ProductTitle/>}
			transform={transform}
		>
			<SimpleForm
				record={record}
				warnWhenUnsavedChanges
				noValidate
				toolbar={<FormToolbar/>}
				sx={{
					padding: {
						md: '32px',
						xs: '16px'
					}
				}}
			>
				<Typography variant="h4">Modification du produit</Typography>
				<Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} justifyItems={'flex-start'}
					   spacing={2}>
					<Typography variant="h6" gutterBottom>Produit #{record?.id}</Typography>
					{record && record.trusted_terroir &&
                        <RouterLink
                            to={getProductLink(record, record.trusted_terroir.terroir)}
                            target={'_blank'}
                        >
                            Voir sur lopt.org
                        </RouterLink>
					}
				</Stack>
				<Grid container display={{xs: 'block', md: 'flex'}} sx={{gap: '30px', justifyContent: 'space-between'}}>
					<Grid item xs={12} md={5}>
						{/*<Typography variant="h6" gutterBottom>*/}
						{/*    Informations*/}
						{/*</Typography>*/}
						<Box sx={{display: 'flex', gap: '20px'}}>
							<TextInput
								disabled
								source="id"
								id="identifiant"
								required
								fullWidth
							/>
							<SelectInput
								isLoading={loading}
								className="selectInput"
								source="is_published"
								format={v => String(v)}
								optionValue="id"
								optionText="LIBELLE"
								label="Publié ?"
								choices={[
									{id: false, LIBELLE: 'Non'},
									{id: true, LIBELLE: 'Oui'}
								]}
								emptyValue="empty"
								fullWidth
								required
								validate={req}
							/>
						</Box>

						<Typography variant="h6" gutterBottom>
							Origine
						</Typography>
						<Box sx={{display: 'flex', gap: 2, mt: 1, mb: 1}}>
							<FunctionField
								render={(record: Product) =>
									<Chip
										label={record.is_contribution ? 'Contribution utilisateur' : 'Recensement L\'OPT'}
										variant="outlined"
										color={record.is_contribution ? 'warning' : 'info'}
									/>
								}
							/>
							<Box>
								<ReferenceField source="contributor" reference="users" label="Utilisateur">
									<TextField source="email"/><br/>
									<FunctionField
										render={(record: User) =>
											<Link
												href={createPath({
													resource: record.is_producer ? 'producers/users' : 'users/clients',
													type: 'edit',
													id: record.id
												})}
												variant={'body2'}
											>Voir l'utilisateur</Link>
										}
									/>
								</ReferenceField>
							</Box>
						</Box>
						<Alert severity="info" style={{width: '100%', marginBottom: 16}}>
							<AlertTitle>Gestion du produit parent</AlertTitle>
							Un produit/spécialité a un parent lorsque son origine découle d'un autre
							produit (exemple : La <u>Moutarde douce au Riesling</u> aura comme parent le
							produit <u>Moutarde douce</u>)
						</Alert>
						<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mb: 3}}>
							<ReferenceInput
								name="parent"
								reference="products"
								source="parent"
								sort={{field: 'name', order: 'ASC'}}
							>
								<AutocompleteInput
									name="parent"
									label={'Produit parent'}
									helperText="Est-ce que ce produit est une déclinaison d'un autre produit ?"
									optionText="name"
									optionValue="id"
									debounce={100}
								/>
							</ReferenceInput>
							<ReferenceField source="parent" reference="products" label="">
								<FunctionField
									source="parent"
									label="Libellé"
									render={(record: Product) =>
										<RALink
											to={createPath({resource: 'products', type: 'edit', id: record.id})}
											style={{textDecoration: 'none'}}
											variant={'body2'}
											target={'_blank'}
											sx={{mb: 15}}
										>
											Voir le produit parent
										</RALink>
									}
								/>
							</ReferenceField>
							{record && record.children && record.children.length > 0 &&
                                <Box>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Déclinaisons
                                    </Typography>
                                    <ReferenceArrayField
                                        label="Déclinaisons"
                                        reference="products"
                                        source="children"
                                    >
                                        <Datagrid bulkActionButtons={false}>
                                            <TextField source="id"/>
                                            <TextField source="name"/>
                                            <ShowButton label={'Modifier'}/>
                                        </Datagrid>
                                    </ReferenceArrayField>
                                </Box>
							}
						</Box>


						<Typography variant="h6" gutterBottom>
							Appellations
						</Typography>

						<TextInput
							source="name"
							fullWidth
							validate={req}
						/>

						<TextInput
							source="name_alt"
							fullWidth
						/>

						<TextInput
							source="article"
							fullWidth
						/>

						<SelectInput
							className="selectInput"
							choices={optionsT}
							isLoading={loading}
							source="type"
							optionText="name"
							optionValue="id"
							emptyValue="empty"
							label="Type"
							fullWidth
							validate={req}
						/>

						<SelectInput
							className="selectInput"
							choices={availableCategories}
							isLoading={loading}
							source="category"
							optionText="name"
							optionValue="id"
							emptyValue="empty"
							label="Catégorie"
							fullWidth
							validate={req}
						/>

						<SelectInput
							className="selectInput"
							choices={[
								{id: false, label: 'Non'},
								{id: true, label: 'Oui'}
							]}
							isLoading={loading}
							source="is_registered_trademark"
							optionText="label"
							optionValue="id"
							// emptyValue="empty"
							helperText={false}
							label="Marque déposée"
							fullWidth
						/>

						<TextInput
							label="Email"
							name="email"
							source="email"
							fullWidth
						/>

						<CheckboxGroupInput
							label="Label(s) de qualité"
							source="labels"
							helperText={false}
							choices={[
								{id: 'is_aoc', name: 'AOC'},
								{id: 'is_igp', name: 'IGP'}
							]}
						/>

						<ReferenceArrayInput
							name="tags"
							label="Tags"
							reference="tags"
							source="tags"
						>
							<AutocompleteArrayInput
								blurOnSelect={false}
								name="tags"
								optionText="name"
								multiple={true}
								optionValue="id"
								debounce={250}
								create={<TagCreate defaultTypeName="Produit"/>}
							/>
						</ReferenceArrayInput>

						<Box sx={{display: 'flex'}}>
							<TextInput
								type="text"
								source="link_external"
								fullWidth
							/>
							{record && record.link_external &&
                                <Link
                                    sx={{
										alignSelf: 'center',
										padding: '0 15px',
										height: '40px',
										pointerEvents: record.link_external ? 'inherit' : 'none',
										cursor: record.link_external ? 'pointer' : 'not-allowed'
									}}
                                    target="_blank"
                                    href={record.link_external}
                                >
                                    <LaunchIcon color={record.link_external ? 'primary' : 'disabled'}/>
                                </Link>
							}
						</Box>

						<TextInput
							source="sources"
							fullWidth
						/>

						<TextInput
							source="created_at"
							disabled={true}
							fullWidth
						/>

						<TextInput
							source="updated_at"
							disabled={true}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid>
							<PreviewImage
								source="image"
								resource="produit"
							/>
						</Grid>
						<Box mt="4em"/>
						{record && record.id &&
                            <ProductContributions productId={record.id}/>
						}
						<Box mt="4em"/>
						{record && record.id && <ProductTerroirs productId={record.id}/>}
						<Box mt="4em"/>
						{record && record.id && <ProductCommunes productId={record.id}/>}
					</Grid>
				</Grid>
				<Box mt="3em"/>
				<DefaultEditor/>
			</SimpleForm>
		</Edit>
	)
}

const req = [required()]

const ProductTitle = () => <FullNameField size="32" sx={{margin: '5px 0'}}/>

const FormToolbar = () => {

	const notify = useNotify()

	return (
		<Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Box sx={{display: 'flex', gap: 5}}>
				<SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>
				<SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
							mutationOptions={{
								onSuccess: (data) => {
									notify('Produit mis à jour', {
										type: 'info',
										messageArgs: {smart_count: 1}
									})
								}
							}}/>
			</Box>
			<DeleteButton/>
		</Toolbar>
	)
}
