import {getProductLink} from '@/lib/link'
import DatagridProducers from '@/producers/components/datagrid'
import TerroirCardInfo from '@/terroirs/components/terroirCardInfo'
import {AppContributionProduct} from '@/types'
import {Alert, AlertTitle, Card, CardContent, CardHeader, Chip, Grid, Stack, Typography} from '@mui/material'
import React, {useRef, useState} from 'react'
import {Pagination, ReferenceManyField, useCreatePath, useEditController} from 'react-admin'
import {MapRef} from 'react-map-gl'
import {Link} from 'react-router-dom'

export default function EditProductContribution() {

	const {record, isLoading: loading} = useEditController<AppContributionProduct>()
	const createPath = useCreatePath()

	const mapRef = useRef<MapRef | null>(null)

	const [viewState, setViewState] = useState({
		zoom: 8,
		...(record && {
			longitude: record.lng,
			latitude: record.lat
		})
	})

	return record ? (
		<>
			<Grid item xs={12} md={6}>
				<Card sx={{height: '100%'}}>
					<CardHeader title={'Produit'}/>
					<CardContent>
						<Stack gap={3}>
							<div>
								<Typography variant="h6" gutterBottom>{record.product.name}</Typography>
								<Typography variant="subtitle1" gutterBottom>{record.product.categoryName}</Typography>
								<Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}
									   justifyItems={'flex-start'} spacing={2}>
									<Link
										to={createPath({resource: 'products', type: 'edit', id: record.product.id})}
									>Voir le produit</Link>
									<Link to={getProductLink(record.product, record.terroir)} target={'_blank'}>Voir sur
										lopt.org</Link>
								</Stack>
							</div>
							<Alert severity="info" style={{width: '100%', marginTop: 20}}>
								<AlertTitle>Traitement partiel automatique</AlertTitle>
								Les valeurs d'héritage et d'existance ont été <strong>automatiquement
								appliquées</strong> à l'indice de confiance du produit pour le
								terroir {record.terroir.name_prefixed}.
							</Alert>
							<div>
								<Typography variant="subtitle2" gutterBottom>Fait-il parti du
									patrimoine {record.terroir.name_prefixed} ?</Typography>
								<Chip
									label={record.heritage ? 'Oui' : 'Non'}
									color={record.heritage ? 'success' : 'error'}
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Existe-il toujours ?</Typography>
								<Chip
									label={record.is_exist ? 'Oui' : 'Non'}
									color={record.is_exist ? 'success' : 'error'}
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Informations complémentaires</Typography>
								<Typography variant="body1" gutterBottom>{record.description || 'N/A'}</Typography>

							</div>
						</Stack>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6}>
				{record.terroir &&
                    <TerroirCardInfo terroir={record.terroir}/>
				}
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Producteurs suggérés</Typography>
						<Alert severity="info" style={{width: '100%', marginTop: 20}}>
							<AlertTitle>Traitement partiel automatique</AlertTitle>
							La production du produit {record.product.name} a déjà été <strong>automatiquement
							créée</strong> pour les producteurs listés ci-dessous.
						</Alert>
					</CardContent>
					{record.producers && record.producers.length > 0 ?
						<ReferenceManyField
							reference="producers"
							source="producers"
							target="id"
							pagination={<Pagination/>}
						>
							<DatagridProducers/>
						</ReferenceManyField>
						: <CardContent>
							<Typography variant="body1" gutterBottom>Aucun producteur suggéré</Typography>
						</CardContent>
					}
				</Card>
			</Grid>
		</>
	) : null
}
