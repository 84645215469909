import {getStatusClass} from '@/app-contributions'
import fetchApi from '@/lib/tools/api'
import {AppContribution, AppContributionProduct, ProductTerroir, Terroir} from '@/types'
import {Box, Chip, Typography} from '@mui/material'
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {
	Datagrid,
	FilterButton,
	FunctionField,
	List,
	ReferenceField,
	SelectInput,
	ShowButton,
	TopToolbar,
	useNotify,
	useSaveContext
} from 'react-admin'

const PARAMS = {
	context: 'terroir',
	endpoints: {
		search: () => `/terroirs`,
		getLinked: (id: number) => `/products/${id}/terroirs`,
		save: (id: number) => `/products/${id}/terroirs`,
		add: (id: number) => `/products/${id}/terroirs/add`,
		delete: (id: number) => `/products/${id}/terroirs/remove`
	}
}

export default function ProductContributions({productId}: { productId: number }) {

	const notify = useNotify()
	const {saving} = useSaveContext()

	const [filter, setFilter] = useState('')
	const [options, setOptions] = useState<Terroir[]>([])
	const [touched, setTouched] = useState<boolean>(false)

	const [linkedOptions, setLinkedOptions] = useState<ProductTerroir[]>([])

	const [loading, setLoading] = useState(false)
	const [loadingSearch, setLoadingSearch] = useState(false)
	const [open, setOpen] = useState(false)


	/**
	 * Event
	 * Find linked product's terroir
	 */
	useEffect(() => {
		handleGetLinked()
	}, [])

	/**
	 * Methods
	 * Search Linked Terroirs
	 */
	const handleGetLinked = useCallback(async () => {
		setLoading(true)

		/**
		 * Liaisons
		 */
		await fetchApi<ProductTerroir[]>(
			PARAMS.endpoints.getLinked(productId)
		)
			.then((res: ProductTerroir[]) => {
				setLinkedOptions(res ?
					res.sort((a: ProductTerroir, b: ProductTerroir) => a.terroir.name.localeCompare(b.terroir.name))
					: []
				)
			})

		setLoadingSearch(false)
		setLoading(false)
	}, [productId])

	const terroirChoices = useMemo(() => linkedOptions.map(terroir =>
		({id: terroir.terroir.id, name: terroir.terroir.name})
	), [linkedOptions])

	return (
		<Box>
			<Typography variant="h6">Contributions utilisateur</Typography>
			<List
				key={'app-contributions-' + productId}
				resource="app-contributions"
				filter={{
					product: productId
				}}
				actions={
					<TopToolbar>
						<FilterButton/>
					</TopToolbar>
				}
				filters={[
					<SelectInput
						label="Terroir"
						source="terroir"
						choices={terroirChoices}
					/>
				]}
			>
				<Datagrid>
					{/*<TextField source="id"/>*/}
					<FunctionField
						label="Statut"
						render={(record: AppContribution) => <>
							<Chip
								sx={{height: 12, width: 12}}
								color={getStatusClass(record.status)}
								// variant="outlined"
								size="small"
							/>
						</>}
					/>
					<ReferenceField sortable={false} source="terroir.id" reference="terroirs"
									label="Terroir"/>
					<FunctionField
						label="Héritage"
						render={(record: AppContributionProduct) => <>
							<Chip
								label={record.heritage ? 'Oui' : 'Non'}
								color={record.heritage ? 'success' : 'error'}
								size="small"
							/>
						</>}
					/>
					<FunctionField
						label="Existe ?"
						render={(record: AppContributionProduct) => <>
							<Chip
								label={record.is_exist ? 'Oui' : 'Non'}
								color={record.is_exist ? 'success' : 'error'}
								size="small"
							/>
						</>}
					/>
					<FunctionField
						label="Autre"
						render={(record: AppContributionProduct) => <>
							<Typography variant={'body2'}>Description
								: {record.description ? 'Oui' : 'Non'}</Typography>
							<Typography variant={'body2'}>Producteur
								: {record.producers.length > 0 ? 'Oui' : 'Non'}</Typography>
						</>}
					/>
					<ShowButton/>
				</Datagrid>
			</List>
		</Box>
	)
}
